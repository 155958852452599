var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-modal',{attrs:{"show":_vm.show}},[_c('div',{staticClass:"dialog-coupon",class:{[`dialog-coupon--type${_vm.color}`]: _vm.color}},[_c('w-button',{staticClass:"dialog-coupon-close",attrs:{"flat":"","icon":"","name":"icon_close","type":"bg1","icon-size":24},on:{"click":_vm.handleClose}}),(_vm.type === 'type1')?_c('div',[_c('i',{staticClass:"dialog-coupon-left-ribbon",style:({
        backgroundImage: _vm.extra.left_ribbon_url ? `url(${_vm.extra.left_ribbon_url})` : '' })}),_c('i',{staticClass:"dialog-coupon-right-ribbon",style:({
        backgroundImage: _vm.extra.right_ribbon_url ? `url(${_vm.extra.right_ribbon_url})` : '' })}),_c('div',{staticClass:"dialog-coupon-header",style:({
        backgroundColor: _vm.extra.header_bg_color,
        color: _vm.extra.header_text_color,
      })},[(_vm.title)?_c('div',{staticClass:"dialog-coupon-title font18b-min",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.coupons && _vm.coupons.length)?_c('div',{staticClass:"dialog-coupon-box"},_vm._l((_vm.coupons),function(item,index){return _c('CouponList',{key:index,attrs:{"coupon":item,"bg":_vm.couponBg,"color":_vm.extra.coupon_text_color}})}),1):_vm._e(),(_vm.point)?_c('div',{staticClass:"dialog-point-box"},[_c('div',{staticClass:"dialog-point-title font16b-min"},[_vm._v(_vm._s(_vm.$t('global.install_app')))]),_c('div',{staticClass:"dialog-point-txt font24r-min font-fin",domProps:{"innerHTML":_vm._s(_vm.$t('global.get_point_worth', { point: _vm.point, worth: `$${_vm.pointEqualPrice}` }))}}),_c('div',{staticClass:"dialog-point-bg"},[_c('img',{attrs:{"src":require("@/assets/images/img_dialog_point.png")}})])]):_vm._e()]),_c('div',{staticClass:"dialog-coupon-contbg",class:{
        'is-countdown': !_vm.bonusText && _vm.remainTime,
        'is-empty': !_vm.bonusText && !_vm.remainTime,
      },style:({
        background: _vm.extra.header_bg_url
          ? `linear-gradient(180deg, ${_vm.extra.header_bg_color} 0%, #ffffff 100%)`
          : '',
      })},[_c('i',{staticClass:"dialog-coupon-header-bg",class:{ 'is-customized': _vm.extra.header_bg_url || _vm.extra.header_bg_url_m },style:({
            '--bg-url': _vm.extra.header_bg_url ? `url(${_vm.extra.header_bg_url})` : '',
            '--bg-url-m': _vm.extra.header_bg_url_m ? `url(${_vm.extra.header_bg_url_m})` : '',
          })}),(_vm.bonusText)?_c('div',{staticClass:"dialog-coupon-bonus font14r"},[_c('span',{staticClass:"title font14b",staticStyle:{"color":"var(--fu-red1)"}},[_vm._v(" "+_vm._s(_vm.$t('new_coupon.bonus'))+": ")]),_c('span',{staticClass:"bonus-content",style:({
            color: _vm.extra.header_text_color,
          }),domProps:{"innerHTML":_vm._s(_vm.bonusText)}})]):(_vm.remainTime)?_c('countdown',{attrs:{"time-info":_vm.timeInfo}}):_vm._e()],1),_c('div',{staticClass:"dialog-coupon-main"},[(_vm.shareUserInfo?.nickname && _vm.$abtest.getVariant('share_tip') === 'new')?[_c('div',{staticClass:"dialog-inviter-info"},[_c('img',{staticClass:"dialog-inviter-avatar",attrs:{"src":_vm.shareUserInfo.profile_pic || _vm.emptyUserAvatar,"alt":""}}),_c('span',{staticClass:"dialog-inviter-name"},[_vm._v(_vm._s(_vm.shareUserInfo.nickname))])]),(_vm.desc)?_c('div',{staticClass:"inviter-discount-title font15r"},[_vm._v(" 👋"),_c('p',{staticClass:"font15r"},[_vm._v(_vm._s(_vm.$t('$share.share_popup_title')))]),_vm._v("🥳 ")]):_vm._e()]:[(_vm.desc)?_c('div',{staticClass:"discount-title font15r",domProps:{"innerHTML":_vm._s(_vm.desc)}}):_vm._e()],_c('div',{staticClass:"dialog-coupon-btn font16b",style:({
            backgroundColor: _vm.extra.btn_bg_color,
            color: _vm.extra.btn_text_color,
          }),on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.confirmBtnText)+" "),(_vm.handShow)?_c('img',{staticClass:"dialog-coupon-hand",attrs:{"src":require("@/assets/images/img_but_hand.png"),"alt":""}}):_vm._e()]),(_vm.cancelBtnText)?_c('w-button',{staticClass:"dialog-coupon-cancel",attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.cancelBtnText))]):_vm._e()],2)]):(_vm.type === 'type2')?_c('div',{staticClass:"dialog-coupon-invite"},[_c('div',{staticClass:"dialog-coupon-info"},[_c('div',{staticClass:"coupon-money",class:{ long: _vm.isLongSumCouponText }},[_c('span',{staticClass:"font24b"},[_vm._v(_vm._s(_vm.currencySymbol))]),_vm._v(_vm._s(_vm._f("formatPrice")(_vm.sumCoupon))+" ")]),_c('div',{staticClass:"coupon-num font20b"},[_vm._v(_vm._s(_vm.$t('$buy.coupon'))+" * "+_vm._s(_vm.coupons.length))])]),_c('div',{staticClass:"save-info"},[_c('div',{staticClass:"save-info-desc font15r"},[_vm._v(_vm._s(_vm.desc))]),_c('div',{staticClass:"dialog-coupon-btn font16b",on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.confirmBtnText)+" "),(_vm.handShow)?_c('img',{staticClass:"dialog-coupon-hand",attrs:{"src":require("@/assets/images/img_but_hand.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"give-up-btn font15r",on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.cancelBtnText)+" "),_c('w-icon',{attrs:{"name":"icon_arrow_right","size":16}})],1)])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }